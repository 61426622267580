import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, colors } from '@material-ui/core';
import { Menu as MenuIcon, Lock } from '@material-ui/icons';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { showModal } from 'store/actions';
import { logout } from '../../../../store/actions/sessionActions';
import {
  setSoundState,
  setNotificationState,
} from '../../../../store/actions/settingsActions';
import LanguageSwitcher from '../../../../components/LanguageSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#006ae4',
  },
  toolbar: {
    '@media (max-width: 960px)': {
      minHeight: '50px',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, handleToggleFilterBar, className, ...rest } =
    props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const settings = useSelector((state) => state.settings);
  const { sound, notificationIsOn } = settings;

  const handleLogout = () => {
    logout()(dispatch); 
    document.location.reload(); 
  };

  const handleToggleSoundState = () => {
    dispatch(setSoundState({ ...sound, isOn: !sound.isOn }));
  };

  const handleToggleNotification = () => {
    dispatch(setNotificationState(!notificationIsOn));
  };

  const handleChangePassword = () => {
    dispatch(showModal('ChangePasswordDialog'));
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar className={clsx(classes.toolbar, className)}>
        <IconButton color="inherit" onClick={handleToggleFilterBar}>
          <MenuIcon />
        </IconButton>
        <LanguageSwitcher color="#fff" />
        <RouterLink to={`/${search}`}>
          <img alt="Logo" src="/img/logo.png" style={{ height: '45px' }} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton color="inherit" onClick={handleToggleNotification}>
          {notificationIsOn ? <NotificationsIcon /> : <NotificationsOffIcon />}
        </IconButton>
        <IconButton color="inherit" onClick={handleToggleSoundState}>
          {sound.isOn ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
        <IconButton color="inherit" onClick={handleChangePassword}>
          <Lock />
        </IconButton>
        <IconButton color="inherit" onClick={handleLogout}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  handleToggleFilterBar: PropTypes.func,
  logout: PropTypes.func,
};

TopBar.defaultProps = {
  className: '',
  onOpenNavBarMobile: () => false,
  handleToggleFilterBar: () => false,
  logout: () => false,
};

export default TopBar;
