import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../components/Select/Select';
import {
  DEFAULT_STATUS_CONDITIONS,
  ROUTING_STATUS_OPTIONS,
  ROUTING_RELATIONAL_OPERATOR_OPTIONS,
} from '../../constants';
import DurationInputs from '../../components/DurationInputs';

const DialogStatusInputs = ({ orCondition, onKeyChange, onValueChange }) => {
  const duration = Object?.values(orCondition)[0]?.duration ?? 0;
  const operator = Object?.values(orCondition)[0]?.operator ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0].value;

  return (
    <>
      <Select
        isFullWidth
        sx={{ maxWidth: '220px' }}
        label="Статус"
        options={ROUTING_STATUS_OPTIONS}
        defaultValue={
          ROUTING_STATUS_OPTIONS.find(
            (o) => o.key === Object.keys(orCondition)[0],
          ) ?? ROUTING_STATUS_OPTIONS[0]
        }
        onChange={(e) =>
          onKeyChange(
            e.target.value.key,
            DEFAULT_STATUS_CONDITIONS[e.target.value.key],
          )
        }
      />
      <Select
        isFullWidth
        sx={{ maxWidth: '180px' }}
        label="Прошло времени"
        options={ROUTING_RELATIONAL_OPERATOR_OPTIONS}
        defaultValue={
          ROUTING_RELATIONAL_OPERATOR_OPTIONS.find(
            (o) => o.value === operator,
          ) ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0]
        }
        onChange={(e) => onValueChange('operator', e.target.value.value)}
      />
      <DurationInputs
        inputStyles={{ maxWidth: '110px', minWidth: '80px' }}
        duration={duration}
        onChange={(d) => onValueChange(d.key, d.value)}
      />
    </>
  );
};
DialogStatusInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
DialogStatusInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
  onValueChange: null,
};

export default DialogStatusInputs;
