import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function TemplateSearchPanel({ setSearchedTemplates }) {
  const { t } = useTranslation();
  const templates = useSelector((state) => state.templates.data);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase(); 
    setSearchedTemplates(
      templates?.filter(
        (temp) =>
          temp.template?.toLowerCase().includes(searchValue) ||
          temp.title?.toLowerCase().includes(searchValue),
      ),
    );
  };

  return (
    <Parent>
      <div className="search-filter">
        <div className="input">
          <input
            onChange={handleSearch}
            type="text"
            placeholder={t('templatesTool.searchPanel')}
          />
        </div>
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #eaeaea;
  .input {
    flex: 2;
    background-image: url(/img/main-search.svg);
    background-repeat: no-repeat;
    background-position: 21px 21px;
    background-color: #fff;
  }
  input {
    width: 100%;
    height: 65px;
    border: none;
    margin-left: 64px;
    outline: none;
    font-size: 15px;
  }
  .search-filter {
    width: 100%;
  }
`;

TemplateSearchPanel.propTypes = {
  setSearchedTemplates: PropTypes.func,
};
TemplateSearchPanel.defaultProps = {
  setSearchedTemplates: () => '',
};
