import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, LinearProgress } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import {  checkRoute } from 'store/actions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getInitials, stringToColor } from 'utils/userHelper';

const getDepartmentUserName = (user) => {
  const { name, surname, lastname } = user;
  if (!name && !surname && !lastname) return 'noname';
  return `${name} ${surname} ${lastname}`;
};

// Styled components
const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1;
`;

const StyledList = styled(List)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledListItem = styled(ListItem)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  align-items: center;
`;

function RouteEmployees({ script }) {
  const dispatch = useDispatch();
  const uuidCompany = _.get(script, 'ids.uuidCompany');
  const employeesConditions = _.get(script, 'conditions.target.employees');
  const dialogsConditions = _.get(script, 'conditions.dialogs');

  const departments = useSelector((state) => state.session.departments);

  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  const companyEmployees = useMemo(() => {
    if (departments && uuidCompany in departments) {
      return _.flatMap(departments[uuidCompany], 'users');
    }
    return [];
  }, [departments, uuidCompany]);

  const testRun = useCallback(() => {
    if (!script || !uuidCompany || !employeesConditions || !dialogsConditions) {
      return;
    }

    setLoading(true);
    dispatch(checkRoute({ dialogsConditions, employeesConditions }))
      .then((result) => {
        const list = [];
        Object.entries(result).forEach(([employeeId, dialogIds]) => {
          const employee = companyEmployees.find((employee) => employee.uuid === employeeId);
        
          if (employee) {
            list.push({ ...employee, dialogs: dialogIds });
          }
        });
        setEmployees(list);
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [companyEmployees, dialogsConditions, dispatch, employeesConditions, script, uuidCompany]);

  return (
    <StyledBox>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "end", paddingRight: 5, paddingBottom: 3 }}>
        <Button onClick={testRun} endIcon={<PlayArrow />}>
          Тестовый запуск
        </Button>
      </div>
      {loading && <LinearProgress />}
      {employees.length > 0 && (
        <StyledList dense sx={{ padding: 0 }}>
          {employees.map((employee, i) => (
            <StyledListItem key={i} disablePadding>
              <p style={{ width: 24 }}>{i + 1}.</p>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: stringToColor(getDepartmentUserName(employee)) }}>
                  {getInitials(getDepartmentUserName(employee))}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={employee.name} secondary={employee.dialogs.length} />
            </StyledListItem>
          ))}
        </StyledList>
      )}
    </StyledBox>
  );
}

RouteEmployees.propTypes = {
  script: PropTypes.object,
};
RouteEmployees.defaultProps = {
  script: null,
};

export default RouteEmployees;
