/* eslint-disable react/no-danger */
import React from 'react';
import marked from 'marked';
import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
// import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import textTransforms from 'utils/textTransform';

// const ListMarkdownWrapper = styled.ul`
//     margin-left:10px
// `;
// const tokenTypes = ['image'];
// const paragraph = (element, parseFunction) => {
//   if (element.type === 'paragraph') {
//     if (element.tokens) {
//       return element.tokens.filter((item) => tokenTypes.includes(item.type)).map(parseFunction)[0];
//     }
//   }
//   return element.text;
// };

// const parse = (element) => {
//   switch (element.type) {
//     case 'image':
//       return element.href && <img src={element.href} width="200" alt={element.text} />;
//     case 'paragraph':
//       return paragraph(element, parse);
//     case 'heading':
//       return <Typography variant={['h1', 'h2', 'h3', 'h4', 'h5', 'h6'][element.depth - 1]}>{element.text}</Typography>;
//     case 'list':
//       return (
//         <ListMarkdownWrapper>
//           {element.items && element.items.map(parse)}
//         </ListMarkdownWrapper>
//       );
//     case 'list_item':
//       return (
//         <li>
//           {element.text}
//         </li>
//       );
//     default:
//       return element.text;
//   }
// };
// const config = {
//   allowedTags: ['b', 'i', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'li', 'em', 'del', 'strong', 'code', 'ol'],
//   disallowedTagsMode: false,
//   selfClosing: ['img', 'br', 'hr'],
//   allowedAttributes: {
//     a: ['href'],
//     img: ['src'],
//   },
// };
function MarkdownElement({ data, isTelegramMarkdown }) {
  const newString = isTelegramMarkdown
    ? textTransforms.textTransform(data)
    : data;
  const renderer = new marked.Renderer();
  renderer.link = (href, title, text) =>
    `<a href="${href}" target="_blank"${
      title ? ` title="${title}"` : ''
    }>${text}</a>`;

  const offList = {
    list() {
      return '';
    },
  };
  marked.use({
    tokenizer: offList,
    renderer,
  });
  
  return (
    <span>{newString}</span>
  
  );
}
MarkdownElement.propTypes = {
  data: PropTypes.string.isRequired,
  isTelegramMarkdown: PropTypes.bool.isRequired,
};

export default MarkdownElement;



