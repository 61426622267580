import { apiGet } from '../../api';
import { HOST } from '../../config';

export const SET_SEARCH_MODE_STATE = 'SET_SEARCH_MODE_STATE';
export const CLOSE_SEARCH_MODE = 'CLOSE_SEARCH_MODE';
export const SET_CURRENT_SEARCH_DIALOG = 'SET_CURRENT_SEARCH_DIALOG';
export const SET_CURRENT_MESSAGE_ID = 'SET_CURRENT_MESSAGE_ID';

export const REQUEST_SEARCH_MESSAGES = 'REQUEST_SEARCH_MESSAGES';
export const RECEIVE_SEARCH_MESSAGES = 'RECEIVE_SEARCH_MESSAGES';
export const ERROR_SEARCH_MESSAGES = 'ERROR_SEARCH_MESSAGES';

export const REQUEST_SEARCH_PERSONES = 'REQUEST_SEARCH_PERSONES';
export const RECEIVE_SEARCH_PERSONES = 'RECEIVE_SEARCH_PERSONES';
export const ERROR_SEARCH_PERSONES = 'ERROR_SEARCH_PERSONES';

export const REQUEST_SEARCH_GROUP_DIALOGS = 'REQUEST_SEARCH_GROUP_DIALOGS';
export const RECEIVE_SEARCH_GROUP_DIALOGS = 'RECEIVE_SEARCH_GROUP_DIALOGS';
export const ERROR_SEARCH_GROUP_DIALOGS = 'ERROR_SEARCH_GROUP_DIALOGS';

export const setSearchModeState = (searchModeState) => ({
  type: SET_SEARCH_MODE_STATE,
  searchModeState,
});

export const closeSearchMode = () => ({
  type: CLOSE_SEARCH_MODE,
});

export const setCurrentSearchDialog = (dialog) => ({
  type: SET_CURRENT_SEARCH_DIALOG,
  dialog,
});

export const setCurrentMessageId = (uuid) => ({
  type: SET_CURRENT_MESSAGE_ID,
  uuid,
});

const requestSearchMessages = () => ({
  type: REQUEST_SEARCH_MESSAGES,
});

const receiveSearchMessages = (data) => ({
  type: RECEIVE_SEARCH_MESSAGES,
  data,
});

const errorSearchMessages = (err) => ({
  type: ERROR_SEARCH_MESSAGES,
  err,
});

export const fetchSearchMessages = (data) => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;
  let url = `${HOST}/chat/search/messages?uuidCompany=${uuidCompany}`;

  if (data.text) {
    url += getState().settings.exactSearch ? `&text=\"${data.text}\"` : `&text=${data.text}`;
  }

  if (data.dateFrom) {
    url += `&dateFrom=${data.dateFrom}`;
  }

  if (data.dateTo) {
    url += `&dateTo=${data.dateTo}`;
  }

  url += `&sort=textScore`;

  dispatch(requestSearchMessages());
  return new Promise((resolve, reject) => {
    apiGet({
      dispatch,
      url,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receiveSearchMessages(res));
        resolve(res);
      },
      error: (err) => {
        dispatch(errorSearchMessages(err));
        reject(err);
      },
    });
  });
};

const requestSearchPersones = () => ({
  type: REQUEST_SEARCH_PERSONES,
});

const receiveSearchPersones = (data) => ({
  type: RECEIVE_SEARCH_PERSONES,
  data,
});

const errorSearchPersones = (err) => ({
  type: ERROR_SEARCH_PERSONES,
  err,
});

export const fetchSearchPersones = (data, page) => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;
  let url = `${HOST}/chat/search/persones?uuidCompany=${uuidCompany}`;

  if (data.text) {
    url += getState().settings.exactSearch ? `&text=\"${data.text}\"` : `&text=${data.text}`;
  }

  if (data.dateFrom) {
    url += `&dateFrom=${data.dateFrom}`;
  }

  if (data.dateTo) {
    url += `&dateTo=${data.dateTo}`;
  }

  if (page) {
    url += `&page=${page}`;
  }

  url += `&sort=textScore`;

  dispatch(requestSearchPersones());
  return new Promise((resolve, reject) => {
    apiGet({
      dispatch,
      url,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receiveSearchPersones(res));

        resolve(res);
      },
      error: (err) => {
        dispatch(errorSearchPersones(err));
        reject(err);
      },
    });
  });
};

const requestSearchGroupDialogs = () => ({
  type: REQUEST_SEARCH_GROUP_DIALOGS,
});

const receiveSearchGroupDialogs = (data) => ({
  type: RECEIVE_SEARCH_GROUP_DIALOGS,
  data,
});

const errorSearchGroupDialogs = (err) => ({
  type: ERROR_SEARCH_GROUP_DIALOGS,
  err,
});


export const fetchSearchGroupDialogs = (data) => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;
  let url = `${HOST}/chat/search/dialogs?uuidCompany=${uuidCompany}`;

  if (data.text) {
    url += getState().settings.exactSearch ? `&text=\"${data.text}\"` : `&text=${data.text}`;
  }

  if (data.dateFrom) {
    url += `&dateFrom=${data.dateFrom}`;
  }

  if (data.dateTo) {
    url += `&dateTo=${data.dateTo}`;
  }

  url += `&sort=textScore`;
  url += `&type=group`;

  dispatch(requestSearchGroupDialogs());
  return new Promise((resolve, reject) => {
    apiGet({
      dispatch,
      url,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receiveSearchGroupDialogs(res));
        resolve(res);
      },
      error: (err) => {
        dispatch(errorSearchGroupDialogs(err));
        reject(err);
      },
    });
  });
};