import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingInByCode } from 'store/actions';

import UnauthorizedRouter from './UnauthorizedRouter';
import PrivateRouter from './PrivateRouter';
import {
  UNAUTH_MAIN_PAGE,
  PRIVATE_MAIN_PAGE,
  UNAUTH_PATHS,
  PRIVATE_PATHS,
} from '../config';


const UnauthRoute = ({
  component: Component, isAuth, location, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      !isAuth ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: PRIVATE_MAIN_PAGE,
          state: { from: props.location },
        }}
        />
      )
    )}
  />
);

UnauthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
};

UnauthRoute.defaultProps = {
  isAuth: false,
  location: {},
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoute = ({ component: Component, isAuth,  ...rest }) => {

  const dispatch = useDispatch();
  const query = useQuery();
  const code = query.get('code');
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    if (code && !isAuth) {
      dispatch(fetchSingInByCode(code)).finally(() => {
        setLoading(false); 
      });
    } else {
      setLoading(false); 
    }
  }, [code, isAuth, dispatch]);

  if (loading) {
    return <div>Loading...</div>; 
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: UNAUTH_MAIN_PAGE,
            state: { from: props.location },
          }}
          />
        )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
};

PrivateRoute.defaultProps = {
  isAuth: false,
  location: {},
};

const Routing = () => {
  // const [isReady,setReadyState] = useState(false);
  const tokenData = useSelector((state) => state.session.tokenData);
  const isAuthenticated = Boolean(tokenData);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          isAuthenticated ? (
            <Redirect to={UNAUTH_MAIN_PAGE} />
          ) : (
            <Redirect to={PRIVATE_MAIN_PAGE} />
          )
        )}
      />
      {UNAUTH_PATHS.map((path) => <UnauthRoute key={path} path={path} component={UnauthorizedRouter} isAuth={isAuthenticated} />)}
      {PRIVATE_PATHS.map((path) => <PrivateRoute key={path} path={path} component={PrivateRouter} isAuth={isAuthenticated} />)}
      {/* <Route component={NotFoundPage} />  TODO */}
    </Switch>
  );
};
Routing.propTypes = {
  state: PropTypes.shape(
    {
      session: PropTypes.shape({
        tokenData: PropTypes.object,
      }),
    },
  ),
};
Routing.defaultProps = {
  state: {},
};

export default Routing;
