import * as actions from '../actions/wsActions';
import { subscribeOrganizationsEvents } from '../actions/chatActions';

let socket = null;
const socketMiddleware = () => {
  

  const onOpen = (store) => (event) => {
    actions.wsConnected({ host: event.target.url })(store.dispatch);
    subscribeOrganizationsEvents()(store.dispatch, store.getState);
  };

  const onClose = (store) => () => {
    actions.wsDisconnected()(store.dispatch);
  };

  const onMessage = (store) => (event) => {
    const payload = JSON.parse(event.data);
    const { responseStatus } = store.getState().ws;
    if (payload.action in responseStatus && responseStatus[payload.action] !== payload.uuid) {
      return false;
    }
    actions.wsRcvMessage(payload)(store.dispatch, store.getState);
    return true;
  };

  // the middleware part of this function
  return (store) => (next) => (action) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }
        // connect to the remote host
        socket = new WebSocket(action.host);
        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case 'WS_SND_MESSAGE':
        socket.send(JSON.stringify(action.payload));
        break;
      default:
        // return next(action);
        break;
    }
    return next(action);
  };
};

export const getSocketInstance = () => socket;

export default socketMiddleware();
