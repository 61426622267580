import React from 'react';
import {
  Avatar, Card, CardActions, CardContent, CardHeader, Grid, IconButton, TextField, Typography,
} from '@material-ui/core';
import {
  AttachFile,
  Delete as DeleteIcon,
  Edit as EditIcon,
  KeyboardReturn as KeyboardReturnIcon,
  MoreVert as MoreVertIcon,
  Send as SendIcon,
} from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import {postTemplateAttachmentThroughBody, clearPostTemplateAttachment} from 'store/actions';
import { Button, Textarea } from '../../../../../../../../components';
import AttachFileForm from '../AttachFileForm/AttachFileForm';

const TemplateCard = styled(Card)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
`;

const File = styled.div`
  padding-bottom: 12px;
  img {
    height: auto;
    width: 150px;
    max-width: 100%;
    border-radius: 3px;
  }
  .file-wrap {
    display: flex;
    justify-content: flex-start;
  }
  .file {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #F4F5F6;
    border-radius: 3px;
    padding: 6px 8px;
    color: #7F7F7F;
  }
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
`;

const TemplateMessage = ({
  uuid,
  avatar,
  userName,
  template,
  title,
  file,
  date,
  onSendTemplate,
  onForwardTemplate,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const currentRef = React.useRef();
  const dispatch = useDispatch();
  const newFile = useSelector((state) => state.templates.attachedFile);
  const [attachedFile, setAttachedFile] = React.useState(file);
  const [isEdit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(template);
  const [caption, setCaption] = React.useState(title);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userInStopList = useSelector((state) => state.person.inStopList);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setValue(template);
  }, [template]);

  React.useEffect(() => {
    setCaption(title);
  }, [title]);

  React.useEffect(() => {
    if (newFile) {
      setAttachedFile(newFile);
    } else if (!attachedFile) {
      setAttachedFile(file);
    }
  }, [newFile]);

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (currentRef.current && !currentRef.current.contains(event.target)) {
        setEdit(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentRef]);

  const handleEdit = () => {
    if (attachedFile) {
      onEdit(uuid, {
        title: caption,
        text: value,
        file: attachedFile,
      });
    } else {
      onEdit(uuid, {title: caption, text: value});
    }
    setEdit(false);
  };
  const handleSend = () => {
    if (file) {
      onSendTemplate({
        text: value,
        file,
      });
    } else {
      onSendTemplate(value);
    }
  };
  const handleInsert = () => {
    if (file) {
      onForwardTemplate({
        text: template,
        file,
      });
    } else {
      onForwardTemplate(template);
    }
  };
  const handleDelete = () => {
    setAnchorEl(false);
    onDelete({
      template,
      uuid,
    });
  };
  const handleAttach = (fileName, data) => {
    dispatch(postTemplateAttachmentThroughBody(fileName, data));
  }
  const handleClearAttachment = () => {
    setAttachedFile(null);
  }
  const handleCancel = () => {
    setEdit(false);
    dispatch(clearPostTemplateAttachment());
  }
  const handleDestroyFileForm = () => {
    dispatch(clearPostTemplateAttachment());
  }
  const formEdit = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField 
            onChange={(e) => setCaption(e.target.value)}
            fullWidth
            margin='dense'
            label={t("templatesTool.title")}
            variant='outlined'
            value={caption}
          />
          <Textarea
            onChange={(e) => setValue(e.target.value)}
            value={value}
            id="template_input"

          />
          <AttachFileForm
            onClear={handleClearAttachment}
            attachedFile={attachedFile}
            onClose={handleDestroyFileForm}
            onAttach={handleAttach}/>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Button
              type="button"
              className="primary"
              style={{ margin: '10px 10px 10px 0' }}
              disabled={value.length < 1}
              onClick={handleEdit}
            >
              {t('templatesTool.save')}
            </Button>
            <Button
              type="button"
              className="secondary"
              onClick={handleCancel}
            >
              {t('templatesTool.cancel')}

            </Button>
          </div>

        </Grid>
      </Grid>
    </>
  );
  const templateContent = (
    <Typography variant="body2" color="textSecondary" component="p">
      <Title>{title}</Title>
      {file && 
        <File>
          {file.mimeType.startsWith('image/') ?
            <img alt="file" src={file.url}/> :
            <div className="file-wrap">
              <div className="file">
                <AttachFile/>
                {file.originName}
              </div>
            </div>
          }
        </File>}
      {template}
    </Typography>
  );

  return (
    <TemplateCard ref={currentRef} key={uuid}>
      <CardHeader
        avatar={(
          <Avatar aria-label="recipe">
            {avatar || (userName && userName.length > 0) && userName[0]}
          </Avatar>
        )}
        action={(
          <div>

            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                 setEdit(true);
                 handleClose();
              }}>
                <Tooltip title={t('templatesTool.edit')}>

                  <EditIcon />
                </Tooltip>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <Tooltip title={t('templatesTool.delete')}>
                  <DeleteIcon />

                </Tooltip>
              </MenuItem>
            </Menu>
          </div>
        )}
        title={userName}
        subheader={moment.unix(date.created)
          .format('LLL')}
      />
      <CardContent>
        {isEdit ? formEdit : templateContent}
      </CardContent>
      {!isEdit && (
        <>
          <CardActions
            disableSpacing
            style={userInStopList ? { cursor: 'not-allowed' } : {}}
          >
            <Tooltip title={t('templatesTool.send')}>
              <span>
                <IconButton
                  aria-label="send"
                  onClick={userInStopList ? null : handleSend}
                  disabled={userInStopList} 
                >
                  <SendIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('templatesTool.insert')}>
              <span>
                <IconButton
                  aria-label="use"
                  onClick={userInStopList ? null : handleInsert}
                  disabled={userInStopList} 
                >
                  <KeyboardReturnIcon />
                </IconButton>
              </span>
            </Tooltip>
          </CardActions>
        </>
      )}


    </TemplateCard>
  );
};
TemplateMessage.propTypes = {
  uuid: PropTypes.string,
  avatar: PropTypes.object,
  template: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.string,
  date: PropTypes.shape({ created: PropTypes.number }),
  onSendTemplate: PropTypes.func,
  onForwardTemplate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  userName: PropTypes.string,
};
TemplateMessage.defaultProps = {
  uuid: 'new_template',
  avatar: undefined,
  template: '',
  title: '',
  file: null,
  date: { created: 0 },
  onSendTemplate: () => ({}),
  onForwardTemplate: () => ({}),
  onEdit: () => ({}),
  onDelete: () => ({}),
  userName: 'default User',
};
export default TemplateMessage;
