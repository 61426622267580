import update from 'immutability-helper';
import _ from 'lodash';
import { A } from '../actions/sessionActions';

const initialState = {
  signIn: {
    isFetching: false,
    error: null,
  },
  refreshToken: {
    isFetching: false,
    error: null,
  },
  getUserInfo: {
    isFetching: false,
    error: null,
  },
  tokenData: null,
  user: {
    companies: [],
  },
  selectedCompany: null,
  currentDialog: null,
  departments: {},
};



const session = (state = initialState, action) => {
  switch (action.type) {
    case A.REQUEST_SIGNIN:
      return update(state, {
        signIn: {
          isFetching: { $set: true },
          error: { $set: null },
        },
      });

    case A.RECEIVE_SIGNIN:
      return update(state, {
        signIn: {
          isFetching: { $set: false },
        },
      });

    case A.SET_COMPANIES: {
      const selectedCompany = !state.selectedCompany
        ? _.get(action, 'payload.0.uuid')
        : state.selectedCompany;
      return update(state, {
        user: {
          $set: {
            ...state.user,
            companies: action.payload,
          },
        },
        selectedCompany: {
          $set: selectedCompany,
        },
      });
    }

    case A.ERROR_SIGNIN:
      return update(state, {
        signIn: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    case A.REQUEST_REFRESH_TOKEN:
      return update(state, {
        refreshToken: {
          isFetching: { $set: true },
          error: { $set: null },
        },
      });

    case A.RECEIVE_REFRESH_TOKEN:
      return update(state, {
        refreshToken: {
          isFetching: { $set: false },
        },
      });

    case A.ERROR_REFRESH_TOKEN:
      return update(state, {
        refreshToken: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    case A.SET_AUTH_TOKEN:
      return update(state, {
        tokenData: { $set: action.data },
      });

    case A.SET_COMPANY:
      return update(state, {
        selectedCompany: { $set: action.uuid },
      });

    case A.LOGOUT:
      return update(state, {
        tokenData: { $set: null },
      });

    case A.REQUEST_GET_USER_INFO:
      return update(state, {
        getUserInfo: {
          isFetching: { $set: true },
          error: { $set: null },
        },
      });

    case A.RECEIVE_GET_USER_INFO:
      return update(state, {
        getUserInfo: {
          isFetching: { $set: false },
        },
      });

    case A.ERROR_GET_USER_INFO:
      return update(state, {
        getUserInfo: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    case A.SET_USER_INFO:
      return update(state, {
        user: { $set: action.data },
      });

    case A.SET_CURRENT_DIALOG: {
      return update(state, {
        currentDialog: { $set: action.uuid },
      });
    }

    case A.UPDATE_DEPARTMENTS: {
      return update(state, {
        departments: { $set: action.payload },
      });
    }

    case A.EMAIL_UPDATE: {
      return update(state, {
        user: {
          $set: {
            ...state.user,
            email: action.email,
          },
        },
      });
    }

    default:
      return state;
  }
};

export default session;
