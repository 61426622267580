function createDefaultConditions(options, additionalOptions) {
  const defaultConditions = {};

  options.forEach((option) => {
    defaultConditions[option.key] = {
      value: option.value,
      ...additionalOptions,
    };
  });

  return defaultConditions;
}
export const ROUTING_MAIN_SELECT = {
  DIALOG_STATUS: 'routingPage.dialogStatus', // Статус диалога
  USER_TAGS: 'routingPage.usersTags', // Теги пользователей
  DIALOG_TAGS: 'Теги диалогов',
};
export const ROUTING_RELATIONAL_OPERATOR_OPTIONS = [
  { value: 'eq', label: 'routingPage.equal' },
  { value: 'gt', label: 'routingPage.greaterThen' },
  { value: 'lt', label: 'routingPage.lessThen' },
  { value: 'gte', label: 'routingPage.moreThanOrEqual' },
  { value: 'lte', label: 'routingPage.lessThanOrEqual' },
  { value: 'neq', label: 'routingPage.notEqual' },
  { value: 'em', label: 'routingPage.empty' },
  { value: 'nem', label: 'routingPage.notEmpty' },
];
export const ROUTING_MAIN_SELECT_OPTIONS = [
  {
    value: ROUTING_MAIN_SELECT.DIALOG_STATUS,
    label: ROUTING_MAIN_SELECT.DIALOG_STATUS,
  },
  // {
  //   value: ROUTING_MAIN_SELECT.USER_TAGS,
  //   label: ROUTING_MAIN_SELECT.USER_TAGS,
  // },
  // TODO: позже может пригодиться
  // {
  //   value: ROUTING_MAIN_SELECT.DIALOG_TAGS,
  //   label: ROUTING_MAIN_SELECT.DIALOG_TAGS,
  // },
];

// префикс UN используется чтобы не усложнять интерфейс (доп проверкой на boolean) для value
export const ROUTING_STATUS_OPTIONS = [
  { key: 'unopened', value: true, label: 'routingPage.new' },
  // { key: 'UNclosed', value: true, label: 'routingPage.opened' },
  { key: 'closed', value: true, label: 'routingPage.notOpened' },
  // { key: 'inWork', value: true, label: 'routingPage.inWork' },
  // { key: 'UNinWork', value: false, label: 'routingPage.dialogClosed' },
];
export const DEFAULT_STATUS_CONDITIONS = createDefaultConditions(
  ROUTING_STATUS_OPTIONS,
  {
    duration: 0,
    operator: ROUTING_RELATIONAL_OPERATOR_OPTIONS[0].value,
  },
);
export const ROUTING_TAGS_OPTIONS = [{ key: 'tags', value: '', label: null }];
export const DEFAULT_TAGS_CONDITIONS = createDefaultConditions(
  ROUTING_TAGS_OPTIONS,
  { duration: 0 },
);
export const DEFAULT_USER_TAGS_CONDITIONS = { tags: [] };
export const DEFAULT_USER_CONDITION = {
  [ROUTING_STATUS_OPTIONS[0].key]: {
    value: ROUTING_STATUS_OPTIONS[0].value,
    operator: ROUTING_RELATIONAL_OPERATOR_OPTIONS[0].value,
    duration: 0,
  },
};

export const DEFAULT_CONDITIONS = {
  [ROUTING_MAIN_SELECT.DIALOG_STATUS]: { ...DEFAULT_STATUS_CONDITIONS },
  [ROUTING_MAIN_SELECT.DIALOG_TAGS]: { ...DEFAULT_TAGS_CONDITIONS },
  [ROUTING_MAIN_SELECT.USER_TAGS]: { ...DEFAULT_USER_TAGS_CONDITIONS },
};

export const EMPTY_ROUTING_SCRIPT = {
  additional: {
    title: 'Сценарий',
    comment: '',
    action: 'assignDialog',
  },
  conditions: {
    dialogs: [],
  },
};
